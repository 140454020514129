import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as stimulus_helper from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "principalPlaceAddressCheckbox",
    "principalPlaceAddress",
    "principalPlaceAddressSection",
    "mailingAddressSelection",
    "mailingAddressPOBoxSection",
    "registeredPlaceAddressSection",
    "registeredBusinessAddressCheckbox"
  ]

  connect() {
    this.showHidePrincipalPlaceAddress(this.principalPlaceAddressCheckboxTarget.checked)
    this.showHidePOBox(inputs.get_choice_from_radio_group(this.mailingAddressSelectionTarget))
    if (this.hasRegisteredBusinessAddressCheckbox) {
      this.showHideRegisteredBusinessAddress(this.registeredBusinessAddressCheckboxTarget.checked)
    }
  }

  principalPlaceAddressChanged(event) {
    this.showHidePrincipalPlaceAddress(event.target.checked)
  }

  showHidePrincipalPlaceAddress(choice) {
    this.principalPlaceAddressSectionTarget.classList.toggle("d-none", !choice)
    this.showHidePrincipalPlaceOption(choice)
  }

  distinctBusinessPlaceChanged(event) {
    this.showHideRegisteredBusinessAddress(event.target.checked)
  }

  showHideRegisteredBusinessAddress(choice) {
    this.registeredPlaceAddressSectionTarget.classList.toggle("d-none", !choice)
  }

  mailingAddressChanged(event) {
    this.showHidePOBox(inputs.get_choice_from_radio_click(event))
  }

  showHidePOBox(choice) {
    if (choice == "postal_address") {
      stimulus_helper.show(this.mailingAddressPOBoxSectionTarget)
    } else {
      stimulus_helper.hide(this.mailingAddressPOBoxSectionTarget)
    }
  }

  showHidePrincipalPlaceOption(choice) {
    if (this.hasMailingAddressSelectionTarget) {
      const principalAddressRadio = this.mailingAddressSelectionTarget.querySelector('input[type=radio][value="principal_business_address"]')
      principalAddressRadio.parentElement.classList.toggle("d-none", !choice)
    }
  }

}
