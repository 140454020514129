import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"
import * as urlHelper from "src/js/url_helper"
import * as mobileHelper from "src/js/mobile_helper"
import * as licenceHelper from "src/js/licence_scan_polling_helper"

export default class extends Controller {
  static targets = [
    "mobile",
    "sendSmsButton",
    "hasMetBorrower",
    "privacyConsentAndIdentBtn",
    "privacyConsentBtn",
    "sendSmsFor",
    "withdrawSmsFor",
    "withdrawSmsButton",
    "identityFields",
    "loadLicenceFromOcrButton",
    "loan",
    "getFinanceQuoteButton",
    "smsSentFor",
    "loadPrivacyConsentButton",
    "idScanStatus"
  ]

  connect() {
    if(this.hasLoanTarget){
      if(this.mobile_needs_validation()){
        this.validateMobile()
      }

      let url;
      if(this.hasLoadLicenceFromOcrButtonTarget) {
        if(url = this.loanTarget.getAttribute("data-id_scan_process_url")) {
          setTimeout(() => {licenceHelper.pollIdScanStatus(url, this.loadLicenceFromOcrButtonTarget, this.idScanStatusTarget, this.withdrawSmsButtonTarget);}, 0);
        }
      }
    } else {
      this.validateMobile()
      if (inputs.get_selected_radio_from_group(this.hasMetBorrowerTarget)) {
        this.togglePrivacyButtons(inputs.get_selected_radio_from_group(this.hasMetBorrowerTarget).value)
      }
    }
  }

  validateMobile() {
    mobileHelper.validateMobileWithButtonTargets(this.mobileTarget, this.sendSmsButtonTargets)
  }

  mobile_needs_validation() {
    if(this.hasLoanTarget && this.loanTarget.getAttribute('cannot_edit_mobile_number') != undefined){
      const cannot_edit_mobile_number = this.loanTarget.getAttribute('cannot_edit_mobile_number')
      if(cannot_edit_mobile_number == "false"){
        return true
      }
    }
    return false
  }

  clickHasMetBorrower(ev) {
    const hasMetBorrower = inputs.get_choice_from_radio_click(ev)
    this.togglePrivacyButtons(hasMetBorrower)
  }

  togglePrivacyButtons(hasMetBorrower) {
    if (hasMetBorrower == '0') {
      // haven't met borrower
      utils.show(this.privacyConsentAndIdentBtnTarget)
      utils.hide(this.privacyConsentBtnTarget)
    } else {
      // met borrower
      utils.show(this.privacyConsentBtnTarget)
      utils.show(this.privacyConsentAndIdentBtnTarget)
    }

  }

  clickedSendSmsButton(ev) {
    this.sendSmsForTarget.value = ev.target.name
  }

  clickedWithdrawSmsButton(ev) {
    if(this.hasWithdrawSmsForTarget) {
      this.withdrawSmsForTarget.value = ev.target.name
    }
  }
}
