import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "potentialChanges",
    "explanation",
  ]

  connect() {
    let value = this.potentialChangesTarget.querySelector("label.active input").value
    this.toggleExplanation(value)
  }

  checkPosition(event) {
    let value = event.target.value
    this.toggleExplanation(value)
  }

  toggleExplanation(value) {
    if (value == 1) {
      utils.show(this.explanationTarget)
    } else  {
      utils.hide(this.explanationTarget)
    }
  }

}
