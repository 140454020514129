import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "documentFrontFile",
    "documentFrontFileLabel",
    "documentFrontFileHelpText",
    "documentBackFile",
    "documentBackFileLabel",
    "documentBackFileHelpText",
    "selfieFile",
    "selfieFileLabel",
    "selfieFileHelpText",
    "shareAddress",
    "uploadButton",
    "message"
  ]

  connect() {
  }

  selectDocumentFront(ev) {
    document.querySelector("#document-front-label").innerHTML = ev.target.value
    const documentBackRow = document.querySelector('#document-back')
    utils.show(documentBackRow)
    this.documentFrontFileLabelTarget.textContent = 'Photo of licence (front)'
    utils.hide(this.documentFrontFileHelpTextTarget)
  }

  selectDocumentBack(ev){
    document.querySelector("#document-back-label").innerHTML = ev.target.value
    const selfieRow = document.querySelector('#selfie')
    utils.show(selfieRow)
    this.documentBackFileLabelTarget.textContent = 'Photo of licence (back)'
    utils.hide(this.documentBackFileHelpTextTarget)
  }

  selectSelfie(ev) {
    document.querySelector("#selfie-label").innerHTML = ev.target.value

    if (this.hasShareAddressTarget) {
      const shareAddressRow = document.querySelector('#share-address')
      utils.show(shareAddressRow)
    } else {
      utils.show(this.uploadButtonTarget)
    }

    this.selfieFileLabelTarget.textContent = 'Photo of you'
    utils.hide(this.selfieFileHelpTextTarget)
  }

  clickShareAddress(ev) {
    utils.show(this.uploadButtonTarget)
  }

  enterIdentificationManually() {
    utils.hide(this.enterIdenficationManuallyButtonTarget)
    utils.show(this.scanButtonTarget)
    utils.show(this.identityFieldsTarget)
    utils.hide(this.idScanIframeTarget)
  }

  upload() {
    utils.hide(this.uploadButtonTarget)
    this.messageTarget.textContent = "Sending..."
    this.messageTarget.classList.add("btn", "btn-primary")
  }

}
