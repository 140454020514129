import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "consentProcess",
    "initiateSmsSection",
    "initiateVerbalConsentSection",
  ]

  connect() {
    this.updateBasedOnConsentProcess()
  }

  consentProcessChanged(e) {
    this.updateBasedOnConsentProcess()
  }

  updateBasedOnConsentProcess() {
    const choice = inputs.get_choice_from_radio_group(this.consentProcessTarget)
    utils.hide(this.initiateSmsSectionTarget)
    utils.hide(this.initiateVerbalConsentSectionTarget)

    switch (choice) {
      case 'all_via_sms':
      case 'consent_via_sms':
        utils.show(this.initiateSmsSectionTarget)
        break
      case 'consent_verbal':
        utils.show(this.initiateVerbalConsentSectionTarget)
        break
    }
  }
}
