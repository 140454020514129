import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "metBorrower",
    "sendPrivacyConsentButton",
    "sendPrivacyConsentAndIdentificationButton"
  ]

  connect() {
    this.toggleSendSmsButtonIfNoConsentsProcessSelection(inputs.get_choice_from_radio_group(this.metBorrowerTarget))
  }

  clickHasMetBorrower(e) {
    const hasMetBorrower = inputs.get_choice_from_radio_click(e)
    this.toggleSendSmsButtonIfNoConsentsProcessSelection(hasMetBorrower)
  }

  toggleSendSmsButtonIfNoConsentsProcessSelection(hasMetBorrower) {
    if (hasMetBorrower == '0') {
      utils.show(this.sendPrivacyConsentAndIdentificationButtonTarget)
      utils.hide(this.sendPrivacyConsentButtonTarget)
    } else {
      utils.show(this.sendPrivacyConsentButtonTarget)
      utils.show(this.sendPrivacyConsentAndIdentificationButtonTarget)
    }

  }

}
