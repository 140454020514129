import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"


export default class extends Controller {
  static targets = [
    "consolidatedDetailsSection",
    "ownOtherPropertiesInput",
    "propertyValueInput"
  ]

  connect() {
    let hasOtherPropertyInputElement = this.ownOtherPropertiesInputTarget.querySelector("input:checked")
    if (hasOtherPropertyInputElement && hasOtherPropertyInputElement.value == "1") {
      utils.show(this.consolidatedDetailsSectionTarget)
    } else {
      utils.hide(this.consolidatedDetailsSectionTarget)
    }
  }

  toggleConsolidatedDetailsSection(event) {
    if (event.target.value == "1") {
      utils.show(this.consolidatedDetailsSectionTarget)
    } else {
      utils.hide(this.consolidatedDetailsSectionTarget)
    }
  }

  updatePropertyValueLabel(event) {
    let value = parseInt(event.target.value)
    if (Number.isInteger(value)) {
      if (value == 1) {
        let text = "Total property value"
        this.propertyValueInputTarget.parentElement.querySelector("label").textContent = text
        this.propertyValueInputTarget.placeholder = text
      } else {
        let text = `Total value of ${value} properties`
        this.propertyValueInputTarget.parentElement.querySelector("label").textContent =  text
        this.propertyValueInputTarget.placeholder = text
      }
    }
  }

}
