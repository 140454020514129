import { Controller } from "stimulus"
import * as mobileHelper from "src/js/mobile_helper"

export default class extends Controller {
  static targets = [
    "mobile",
    "sendSmsButton",
    "sendSmsFor"
  ]

  connect() {
    this.validateMobileWithButtonTargets(this.mobileTarget, this.sendSmsButtonTargets)
  }

  validateMobile() {
    mobileHelper.validateMobile(this.mobileTarget, this.sendSmsButtonTarget)
  }

  validateMobileWithButtonTargets() {
    mobileHelper.validateMobileWithButtonTargets(this.mobileTarget, this.sendSmsButtonTargets)
  }

  clickedSendSmsButton(e) {
    this.sendSmsForTarget.value = e.target.name
  }

}
