import * as urlHelper from "src/js/url_helper"

export function pollIdScanStatus(url, loadLicenceFromOcrButtonTarget, idScanStatusTarget, withdrawSmsButtonTarget ) {
  let shouldPoll = loadLicenceFromOcrButtonTarget.getAttribute("data-poll");
  let pageDetail = loadLicenceFromOcrButtonTarget.getAttribute("data-page");

  url = urlHelper.updateUrlParameter(url, {pageDetail: pageDetail})
  if(!shouldPoll) return;
  if(!document.body.contains(loadLicenceFromOcrButtonTarget)) return;

  doPollOfIdScanStatusWithTimeout(url, loadLicenceFromOcrButtonTarget, idScanStatusTarget, withdrawSmsButtonTarget, pageDetail == "parallel_polling", 6000, null)
}

export function doPollOfIdScanStatusWithTimeout(url, loadLicenceFromOcrButtonTarget, idScanStatusTarget, withdrawSmsButtonTarget, parallelPollingFlag, pollIdScanInterval, pollIdScanPrevious ) {
  fetch(url, {
    method: 'GET',
    headers: {
               "Content-Type": "application/json",
               "Accept": "application/json"
            }
    }).then((response) => response.json())
      .then(data => {
        if (data.reload_required) {
          Turbo.visit(window.location)
        }

        let enableButton = false;
        let statusText = ('status_text' in data) ? data.status_text : "Waiting for customer action"
        let buttonLabel = ('button_label' in data) ? data.button_label : "Load licence information"
        let disableWithdrawSmsButton = false;
        let restart = true;
        let latestStatus = data.status;

        switch(latestStatus) {
          case "processing":
            enableButton = true;
            // disableWithdrawSmsButton = true;
            if(!data.image_upload_completed && !data.id_scan_response_received && parallelPollingFlag) {
              enableButton = false;
            }
            break;
          case "done":
            enableButton = true;
            disableWithdrawSmsButton = true;
            restart = false;
            loadLicenceFromOcrButtonTarget.classList.remove("btn-outline-primary")
            loadLicenceFromOcrButtonTarget.classList.add("btn-green")
            break;
        }

        loadLicenceFromOcrButtonTarget.disabled = !enableButton;
        loadLicenceFromOcrButtonTarget.innerText = buttonLabel;
        idScanStatusTarget.innerHTML = statusText;
        if(withdrawSmsButtonTarget) {
          withdrawSmsButtonTarget.disabled = disableWithdrawSmsButton;
        }

        if(restart) {
          if(pollIdScanPrevious == latestStatus) {
            pollIdScanInterval = pollIdScanInterval + 1000
            if (pollIdScanInterval > 600000) pollIdScanInterval = 600000
          } else {
            pollIdScanInterval = 6000
          }
          setTimeout(() => {doPollOfIdScanStatusWithTimeout(url, loadLicenceFromOcrButtonTarget, idScanStatusTarget, withdrawSmsButtonTarget, parallelPollingFlag, pollIdScanInterval, latestStatus);}, pollIdScanInterval)
        } else {
          loadLicenceFromOcrButtonTarget.setAttribute("data-poll", "0");
        }
      });
}
