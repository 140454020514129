import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    'contact',
    'acknowledgementsDependentField',
    'guarantorDependantField',
    'ultimateBeneficialOwnerDependantField',
    'actingAsGuarantor',
    'ultimateBeneficialOwner'
  ]

  connect() {
    this.render()
  }

  render() {
    if (this.requireAcknowledgements()) {
      utils.showTargets(this.contactTargets)
    } else {
      utils.hideTargets(this.contactTargets)
    }

    if (this.requireGuarantorDependantFields()) {
      utils.showTargets(this.guarantorDependantFieldTargets)
    } else {
      utils.hideTargets(this.guarantorDependantFieldTargets)
    }

    if (this.hasUltimateBeneficialOwnerTarget) {
      if (this.requireUltimateBeneficialOwnerDependantFields() || this.requireGuarantorDependantFields()) {
        utils.showTargets(this.ultimateBeneficialOwnerDependantFieldTargets)
      } else {
        utils.hideTargets(this.ultimateBeneficialOwnerDependantFieldTargets)
      }
    }

  }

  relationshipChanged(e) {
    this.render()
  }

  requireAcknowledgements() {
    if (this.acknowledgementsDependentFieldTargets.length == 0) {
      return true
    } else {
      return this.acknowledgementsDependentFieldTargets.some( f => f.checked )
    }
  }

  requireGuarantorDependantFields() {
    if (this.hasActingAsGuarantorTarget) {
      return this.actingAsGuarantorTarget.checked
    } else {
      return true
    }
  }

  requireUltimateBeneficialOwnerDependantFields() {
    if (this.hasUltimateBeneficialOwnerTarget) {
      return this.ultimateBeneficialOwnerTarget.checked
    }
    return false
  }

  actingAsGuarantorChanged(e) {
    this.render()
  }

}
