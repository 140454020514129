import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "hasIncomeField",
    "extraIncomeFields",
  ]

  extraIncomeChanged(ev) {
    this.changeExtraIncome(ev.target)
  }

  changeExtraIncome(target) {
    const checked = target.checked
    const income_type = target.dataset.for
    target.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.extraIncomeFieldsTargets.forEach(e => {
      if (e.dataset.for == income_type) {
        this.toggleVisibilityAndClearIncome(e, checked)
      }
    })
  }

  toggleVisibilityAndClearIncome(target, flag) {
    utils.toggle(target, !flag)
    let incomeField = target.querySelector('input[type=text]')
    let incomeBasisField = target.querySelector('select')
    if (flag == true) {
      incomeField.value = null
      // defaulted to net_monthly
      $(incomeBasisField).val('net_monthly').trigger('change')
    } else {
      $(target.querySelector("span")).popover("dispose")
    }
  }

}
