export function validateMobile(mobileTarget, sendSmsButtonTarget) {
  let validMobile = isMobileValid(mobileTarget)
  const target = sendSmsButtonTarget
  if(!validMobile) {
    target.classList.add("disabled")
    target.disabled = "disabled"
  } else {
    target.classList.remove("disabled")
    target.disabled = ""
  }
}

export function validateMobileWithButtonTargets(mobileTarget, sendSmsButtonTargets) {
  let validMobile = isMobileValid(mobileTarget)
  sendSmsButtonTargets.forEach(target => {
    if(!validMobile) {
      target.classList.add("disabled")
      target.disabled = "disabled"
    } else {
      target.classList.remove("disabled")
      target.disabled = ""
    }
  })
}

export function isMobileValid(mobileTarget) {
  let validMobile = false
  let mobile = mobileTarget.value
  if (mobile) {
    mobile = strip_mobile(mobile)
    if (/^04\d{8}$/.test(mobile)) {
      validMobile = true
    }
  }
  return validMobile
}

export function strip_mobile(mobile) {
  // strip out spaces, hyphens and parens, then validate if mobile number is 10 digits
  return mobile.replace(/[- \(\)]/g, '')
}
