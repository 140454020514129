import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "privacyConsent",
    "visa",
    "mailingAddress",
    "mailingAddressSection",
    "mailingAddressCheckbox",
    "requirePrivacyConsent",
    "coBorrowerAddressSection",
    "sharesCurrentAddress",
    "sharesMedicareNumber",
    "maritalStatusGroupCode",
    "primaryBorrowerMedicareCardNumber",
    "primaryBorrowerNumberOfDependents",
    "medicareCardNumber",
    "coBorrowerRelationshipToPrimary",
    "greenMedicareCardExpiry",
    "otherMedicareCardExpiry",
    "medicareCardColour",
    "medicareFields",
    "noMedicareDetails",
    "medicareCardPosition",
    "visaSection"
  ]

  connect() {
    this.render()
  }

  render() {
    this.showMedicareExpiryDateField(inputs.get_choice_from_radio_group(this.medicareCardColourTarget))
    this.handlePrivacyConsentInitialState()
    this.showHideVisaAdditionalField(inputs.get_choice_from_radio_group(this.visaTarget))
    // this.showHideMailingAddress(this.mailingAddressCheckboxTarget.checked)
    if (this.hasCoBorrowerRelationshipToPrimaryTarget) {
      const is_coborrower = this.coBorrowerRelationshipToPrimaryTarget.getAttribute('is_coborrower')
      if (is_coborrower == "true") {
        const is_spouse = Number(this.coBorrowerRelationshipToPrimaryTarget.getAttribute('is_spouse'))
        const shares_address = Number(this.coBorrowerRelationshipToPrimaryTarget.getAttribute('shares_current_address'))
        const shares_medicare_number = Number(this.coBorrowerRelationshipToPrimaryTarget.getAttribute('shares_medicare_number'))
        const shares_dependents = Number(this.coBorrowerRelationshipToPrimaryTarget.getAttribute('shares_dependents'))

        this.onPageLoadSetVisibility(is_spouse, shares_address, shares_medicare_number, shares_dependents)
      } else {
        this.handlePrimaryBorrowerNoMedicareDetailsChanged(!this.noMedicareDetailsTarget.checked)
      }
    } else {
      this.enableDisableMedicareFields(this.noMedicareDetailsTarget.checked, false)
    }
  }

  handlePrivacyConsentInitialState() {
    let v = this.privacyConsentTarget.value;
    let consentChecked = (v && v != "0")
    this.handlePrivacyConsentFlag(consentChecked);
  }

  privacyConsentChanged(ev) {
    this.handlePrivacyConsentFlag(ev.target.checked)
  }

  handlePrivacyConsentFlag(consentChecked) {
    const enableEdit = !(this.requirePrivacyConsentTarget.value == "true") || consentChecked
    this.element.querySelectorAll('input,select,textarea').forEach(e => {
      if (e != this.privacyConsentTarget) {
        if (enableEdit) {
          e.readonly = false
          e.disabled = false
        } else {
          e.readonly = true
          e.disabled = true
        }
      }
    })
    this.element.querySelectorAll('.radio label.btn').forEach(e => {
      if (enableEdit) {
        e.classList.remove('disabled')
      } else {
        e.classList.add('disabled')
      }
    })
  }

  primaryBorrowerNoMedicareDetailsChanged(ev){
    let choice = ev.target.checked
    this.handlePrimaryBorrowerNoMedicareDetailsChanged(!choice)
  }

  handlePrimaryBorrowerNoMedicareDetailsChanged(choice){
    this.handleNoMedicareDetailsChanged(!choice)
    // find coborrower shares_medicare_number_flag and click "No"
    let coborrowers = document.querySelectorAll('.coBorrower')
    coborrowers.forEach(coborrower => {
      let coborrowerSharesMedicareFlag = coborrower.querySelector(".shareMedicareNumberFlag")
      if(!choice){
        if(inputs.get_choice_from_radio_group(coborrowerSharesMedicareFlag) == 1){
          inputs.set_radio_value(coborrowerSharesMedicareFlag, "0")
          coborrowerSharesMedicareFlag.dispatchEvent(new Event('click'))
        }
        utils.hide(coborrowerSharesMedicareFlag)
      } else {
        utils.show(coborrowerSharesMedicareFlag)
      }
    })
  }

  coBorrowerNoMedicareDetailsChanged(ev){
    this.enableDisableMedicareFields(ev.target.checked, inputs.get_choice_from_radio_group(this.sharesMedicareNumberTarget))
  }

  enableDisableMedicareFields(noMedicareChecked, sharesMedicareWithPrimaryFlag){
    if(!noMedicareChecked){
      if(sharesMedicareWithPrimaryFlag==1){
        this.displayMedicareFieldsPerSelection("edit_position")
      } else {
        this.displayMedicareFieldsPerSelection("edit_everything")
      }
    } else{
      this.displayMedicareFieldsPerSelection("cannot_edit")
    }
  }

  handleNoMedicareDetailsChanged(noMedicareChecked){
    this.medicareFieldsTarget.querySelectorAll('input,select,textarea').forEach(e => {
      if (noMedicareChecked) {
        // disable fields if un-checked
        e.setAttribute("readonly","readonly")
        e.disabled = true
      } else {
        // enable fields if checked
        e.removeAttribute("readonly")
        e.disabled = false
      }
    })
    this.medicareFieldsTarget.querySelectorAll('.radio label.btn').forEach(e => {
      if (noMedicareChecked) {
        // disable fields if un-checked
        e.classList.add('disabled')
      } else {
        // enable fields if checked
        e.classList.remove('disabled')
      }
    })
    this.medicareFieldsTarget.classList.toggle('d-none', noMedicareChecked)
  }

  citizenshipGroupChanged(ev) {
    this.showHideVisaAdditionalField(inputs.get_choice_from_radio_click(ev))
  }

  showHideVisaAdditionalField(choice) {
    this.visaSectionTarget.classList.toggle("d-none", !(this.visaIsTemporary(choice)))
  }

  visaIsTemporary(choice) {
    return (choice == 'other_temp')
  }

  distinctMailingAddressChanged(event) {
    this.showHideMailingAddress(event.target.checked)
  }

  showHideMailingAddress(choice) {
    this.mailingAddressSectionTarget.classList.toggle("d-none", !choice)
  }

  onPageLoadSetVisibility(relationshipToPrimaryFlag, sharesAddressFlag, shares_medicare_number, shares_dependents){
    if(relationshipToPrimaryFlag == '1') {
      inputs.set_radio_value(this.maritalStatusGroupCodeTarget, "1")
      utils.hide(this.maritalStatusGroupCodeTarget)
      this.setCurrentAddress(sharesAddressFlag)
      this.enableDisableMedicareFields(this.noMedicareDetailsTarget.checked, shares_medicare_number)
    } else {
      utils.show(this.maritalStatusGroupCodeTarget)
      this.setCurrentAddress(sharesAddressFlag)
      this.enableDisableMedicareFields(this.noMedicareDetailsTarget.checked, shares_medicare_number)
    }
  }

  sharesCurrentAddressChanged(event){
    this.setCurrentAddress(inputs.get_choice_from_radio_click(event))
  }

  setCurrentAddress(sharesCurrentAddressFlag){
    if(sharesCurrentAddressFlag == 1){
      utils.hide(this.coBorrowerAddressSectionTarget)
    } else {
      utils.show(this.coBorrowerAddressSectionTarget)
    }
  }

  sharesMedicareNumberChanged(event){
    if(inputs.get_choice_from_radio_click(event) == 1){
      this.assignValuesToMedicareFieldsPerSelection(true)

      this.displayMedicareFieldsPerSelection("edit_position")
    } else {
      this.assignValuesToMedicareFieldsPerSelection(false)
        this.handlePrimaryBorrowerNoMedicareDetailsChanged(false)
      if(!this.noMedicareDetailsTarget.checked){
        this.displayMedicareFieldsPerSelection("edit_everything")
      } else {
        this.displayMedicareFieldsPerSelection("cannot_edit")
      }
    }
  }

  primaryMedicareCardNumberChanged(event){
    let coborrowers = document.querySelectorAll('.coBorrower')
    coborrowers.forEach(coborrower => {
      let coborrowerMedicareField = coborrower.querySelector(".coBorrowerMedicareCardNumberField").querySelector('input')
      if(inputs.get_choice_from_radio_group(coborrower.querySelector(".shareMedicareNumberFlag")) == 1){
        coborrowerMedicareField.value = event.target.value
      }
    })
  }

  primaryGreenMedicareCardExpiryChanged(event){
    let coborrowers = document.querySelectorAll('.coBorrower')
    coborrowers.forEach(coborrower => {
      let coborrowerMedicareField = coborrower.querySelector(".coBorrowerGreenMedicareCardExpiryField").querySelector('input')
      if(inputs.get_choice_from_radio_group(coborrower.querySelector(".shareMedicareNumberFlag")) == 1){
        coborrowerMedicareField.value = event.target.value
      }
    })
  }

  primaryMedicareCardColourChanged(event){
    let coborrowers = document.querySelectorAll('.coBorrower')
    coborrowers.forEach(coborrower => {
      let coborrowerMedicareField = coborrower.querySelector(".coBorrowerMedicareCardColourField").querySelector('input')
      if(inputs.get_choice_from_radio_group(coborrower.querySelector(".shareMedicareNumberFlag")) == 1){
        coborrowerMedicareField.value = event.target.value
      }
    })
    this.medicareCardColourChanged(event)
  }

  primaryOtherMedicareCardExpiryChanged(event){
    let coborrowers = document.querySelectorAll('.coBorrower')
    coborrowers.forEach(coborrower => {
      let coborrowerMedicareField = coborrower.querySelector(".coBorrowerOtherMedicareCardExpiryField").querySelector('input')
      if(inputs.get_choice_from_radio_group(coborrower.querySelector(".shareMedicareNumberFlag")) == 1){
        coborrowerMedicareField.value = event.target.value
      }
    })
  }

  primaryBorrowerMaritalStatusSelected(event){
    let coBorrowers = document.querySelectorAll('.coBorrower')
    const maritalStatusGroupSelectedChoice = inputs.get_choice_from_radio_click(event)
    inputs.set_radio_value(this.maritalStatusGroupCodeTarget, maritalStatusGroupSelectedChoice)
  }

  assignValuesToMedicareFieldsPerSelection(shareMode){
    let primaryBorrowerMedicareNumber = document.getElementById("loan_individual_borrower_primary_borrower_attributes_medicare_card_number").value
    let primaryBorrowerGreenMedicareExpiry = document.getElementById("loan_individual_borrower_primary_borrower_attributes_green_medicare_card_expiry").value
    let primaryBorrowerOrdinaryMedicareExpiry = document.getElementById("loan_individual_borrower_primary_borrower_attributes_other_medicare_card_expiry").value
    let primaryBorrowerMedicareCardColour = inputs.get_choice_from_radio_group(document.getElementById("loan_individual_borrower_primary_borrower_attributes_medicare_card_colour"))
    switch(shareMode){
      case true:
        this.medicareCardNumberTarget.value = primaryBorrowerMedicareNumber
        inputs.set_radio_value(this.medicareCardColourTarget, primaryBorrowerMedicareCardColour)
        this.greenMedicareCardExpiryTarget.querySelector('input').value=primaryBorrowerGreenMedicareExpiry
        this.otherMedicareCardExpiryTarget.querySelector('input').value=primaryBorrowerOrdinaryMedicareExpiry
        break;
      case false:
        this.medicareCardNumberTarget.value = null
        inputs.set_radio_value(this.medicareCardColourTarget, "")
        this.greenMedicareCardExpiryTarget.querySelector('input').value=null
        this.otherMedicareCardExpiryTarget.querySelector('input').value=null
        break;
    }
  }

  displayMedicareFieldsPerSelection(editMode){
    switch(editMode){
      case "edit_position":
        this.noMedicareDetailsTarget.checked = false
        this.noMedicareDetailsTarget.removeAttribute("checked")
        this.medicareFieldsTarget.classList.toggle('d-none', this.noMedicareDetailsTarget.checked)
        utils.hide(this.noMedicareDetailsTarget)
        utils.hide(this.noMedicareDetailsTarget.parentElement.parentElement)
        utils.hide(this.medicareCardColourTarget)
        utils.hide(this.medicareCardNumberTarget)
        utils.hide(this.medicareCardNumberTarget.parentElement.parentElement)
        utils.hide(this.greenMedicareCardExpiryTarget)
        utils.hide(this.greenMedicareCardExpiryTarget.parentElement.parentElement)
        utils.hide(this.otherMedicareCardExpiryTarget)
        utils.hide(this.otherMedicareCardExpiryTarget.parentElement.parentElement)
        this.medicareCardPositionTarget.removeAttribute("readonly")
        this.medicareCardPositionTarget.disabled=false
        this.noMedicareDetailsTarget.dispatchEvent(new Event("click"))
        break;
      case "edit_everything":
        utils.show(this.noMedicareDetailsTarget.parentElement.parentElement)
        utils.show(this.noMedicareDetailsTarget)
        utils.show(this.medicareCardColourTarget)
        utils.show(this.medicareCardNumberTarget)
        utils.show(this.medicareCardNumberTarget.parentElement.parentElement)
        this.showMedicareExpiryDateField("")
        this.handleNoMedicareDetailsChanged(false)
        break;
      case "cannot_edit":
        this.handleNoMedicareDetailsChanged(true)
        break;
    }
  }

  showMedicareExpiryDateField(choice){
    if(choice=="G"){
      this.otherMedicareCardExpiryTarget.querySelector('input').value=null
      utils.hide(this.otherMedicareCardExpiryTarget)
      utils.hide(this.otherMedicareCardExpiryTarget.parentElement.parentElement)
      this.greenMedicareCardExpiryTarget.querySelector('input').value=null
      utils.show(this.greenMedicareCardExpiryTarget)
      utils.show(this.greenMedicareCardExpiryTarget.parentElement.parentElement)
    } else {
      this.greenMedicareCardExpiryTarget.querySelector('input').value=null
      utils.hide(this.greenMedicareCardExpiryTarget)
      utils.hide(this.greenMedicareCardExpiryTarget.parentElement.parentElement)
      utils.show(this.otherMedicareCardExpiryTarget)
      this.otherMedicareCardExpiryTarget.querySelector('input').value=null
      utils.show(this.otherMedicareCardExpiryTarget.parentElement.parentElement)
    }
  }

  medicareCardColourChanged(ev){
    if (ev.target.classList.contains("btn"))
      this.showMedicareExpiryDateField(inputs.get_choice_from_radio_click(ev))
  }
}
