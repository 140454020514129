import { Controller } from "stimulus"
import * as utils from 'src/js/stimulus_helper'
import * as formatter from 'src/js/format_helper'

export default class extends Controller {
  static targets = [ "other", "description", "houseHoldLivingExpenseField", "totalExpense"]

  connect() {
    this.changeStatus()
    this.calculateTotalExpense()
  }

  changeStatus() {
    utils.toggle(this.descriptionTarget, (formatter.moneyToAmount(this.otherTarget.value) || 0) <= 0)
  }

  calculateTotalExpense() {
    const reducer = (total, target) => total + this.monthlyAmount(target)
    const totalExpense = formatter.money(this.houseHoldLivingExpenseFieldTargets.reduce(reducer, 0), 0)
    this.data.set('total-expense', totalExpense)
    setTimeout(() => this.totalExpenseTarget.textContent = this.data.get('total-expense'), 1000)

  }

  monthlyAmount(target) {
    const amount = target.querySelector('input[type=text]').value
    const amountInNumber = formatter.moneyToAmount(amount) || 0
    const amountBasis = target.querySelector('input[type=radio]:checked').value
    let calculatedMonthlyAmount = 0
    switch (amountBasis) {
      case 'weekly':
        calculatedMonthlyAmount = amountInNumber * 52 / 12
        break
      case 'monthly':
        calculatedMonthlyAmount = amountInNumber
        break
      case 'yearly':
        calculatedMonthlyAmount = amountInNumber / 12
        break
    }
    return calculatedMonthlyAmount
  }

}
