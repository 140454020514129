import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"

export default class extends Controller {
  static targets = [ "numberOfJobs", "job0", "job1", "job2", "job3", "job4"]

  connect() {
    inputs.set_active_class_on_radios(this.numberOfJobsTarget)
    this.showHideBasedOnNumberOfJobs(Number(inputs.get_choice_from_radio_group(this.numberOfJobsTarget)))
  }

  numberOfJobsChanged(ev) {
    this.showHideBasedOnNumberOfJobs(Number(inputs.get_choice_from_radio_click(ev)))
  }

  showHideBasedOnNumberOfJobs(n_jobs) {
    if (this.hasJob0Target) {
      this.job0Target.classList.toggle('d-none', n_jobs < 1)
    }
    if (this.hasJob1Target) {
      this.job1Target.classList.toggle('d-none', n_jobs < 2)
    }
    if (this.hasJob2Target) {
      this.job2Target.classList.toggle('d-none', n_jobs < 3)
    }
    if (this.hasJob3Target) {
      this.job3Target.classList.toggle('d-none', n_jobs < 4)
    }
  }
}
