import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "hasCoborrower",
    "considerSpouseContribution",
    "coborrowerFieldset",
    "spouseFieldset",
    "spouseDetailsFieldset",
    "selectCoborrowerFieldset",
    "employmentDetailsFieldset",
    "employmentNoIncomeDetailsFieldset",
    "employmentIncomeDetailsFieldset",
    "spouseConsentsFieldset",
    "maritalStatusGroupCode",
    "capacityConfirmation",
    "isSpousalIncomePaidIntoVerificationAccount",
    "spouseDetailsAddressFieldset"
  ]

  connect() {
    const married = inputs.get_choice_from_radio_group(this.maritalStatusGroupCodeTarget)
    this.handleMaritalStatusSelected(married)
    if (this.hasConsiderSpouseContributionTarget) {
      const flag = inputs.get_choice_from_radio_group(this.considerSpouseContributionTarget)
      this.handleConsiderSpouseContributionSelection(flag)
    }

  }

  hasCoborrowerChanged(e) {
    const flag = inputs.get_choice_from_radio_group(this.considerSpouseContributionTarget)
    let noLabelElementId = this.coborrowerFieldsetTarget.querySelector("input[value='0']").id
    switch(flag) {
      case 'add_as_coborrower':
        // document.querySelector(`label[for=${noLabelElementId}]`).click()
        break;
      case 'consider_income_contribution':
        document.querySelector(`label[for=${noLabelElementId}]`).click()
        break;
      case 'no_income_contribution':
        break;
      default:
        break;
    }
  }

  maritalStatusSelected(e) {
    const married = inputs.get_choice_from_radio_click(e)
    this.handleMaritalStatusSelected(married)
  }

  handleMaritalStatusSelected(married) {
    if (married == 1) {
      // If married, hide coborrower section and show spouse section
      utils.hide(this.coborrowerFieldsetTarget)
      utils.show(this.spouseFieldsetTarget)
      // spouse
      const considerSpouseContributionFlag = inputs.get_choice_from_radio_group(this.considerSpouseContributionTarget)
      this.handleConsiderSpouseContributionSelection(considerSpouseContributionFlag)
    } else {
      // If not married, show coborrower section and hide spouse section
      utils.show(this.coborrowerFieldsetTarget)
      utils.hide(this.spouseFieldsetTarget)
    }

  }

  getHasCoborrowerTarget() {
    return utils.findTargetFromTargetsByClass(this.hasCoborrowerTargets, "spouse", false)
  }

  getHasCoborrowerInSpouseTarget() {
    return utils.findTargetFromTargetsByClass(this.hasCoborrowerTargets, "spouse", true)
  }

  isSpousalIncomePaidIntoVerificationAccountChanged(e) {
    if (this.hasIsSpousalIncomePaidIntoVerificationAccountTarget) {
      if (this.hasCapacityConfirmationTarget) {
        if (e.target.checked) {
          utils.hide(this.capacityConfirmationTarget)
        } else {
          utils.show(this.capacityConfirmationTarget)
        }
      }
    }
  }

  considerSpouseContributionChanged(e) {
    const considerSpouseContributionFlag = inputs.get_choice_from_radio_click(e)
    this.handleConsiderSpouseContributionSelection(considerSpouseContributionFlag)
  }

  handleConsiderSpouseContributionSelection(considerSpouseContributionFlag) {
    switch(considerSpouseContributionFlag) {
      case 'add_as_coborrower':
        // Add as Co-borrower
        utils.hide(this.spouseDetailsFieldsetTarget)
        utils.show(this.coborrowerFieldsetTarget)
        utils.hide(this.selectCoborrowerFieldsetTarget)
        // Make sure hasCoborrower is set to yes
        this.handleHasCoborrowerChange('1')
        break
      case 'consider_income_contribution':
        // Consider income contribution
        utils.show(this.spouseDetailsFieldsetTarget)
        utils.hide(this.coborrowerFieldsetTarget)
        utils.showTargets(this.spouseConsentsFieldsetTargets)
        // Make sure hasCoborrower is set to no as there will be no co-borrower required when adding contributing spouse
        this.handleHasCoborrowerChange('0')

        // select one spouse by default
        this.handleHasCoborrowerInSpouseChange(true)
        utils.show(this.employmentNoIncomeDetailsFieldsetTarget)
        if (this.hasSpouseDetailsAddressFieldsetTarget) {
          utils.show(this.spouseDetailsAddressFieldsetTarget)
        }
        break
      case 'no_income_contribution':
        // Do not consider income contribution
        utils.hide(this.spouseDetailsFieldsetTarget)
        utils.show(this.coborrowerFieldsetTarget)
        utils.show(this.selectCoborrowerFieldsetTarget)

        this.handleHasCoborrowerInSpouseChange(true)
        utils.hide(this.employmentNoIncomeDetailsFieldsetTarget)
        if (this.hasSpouseDetailsAddressFieldsetTarget) {
          utils.hide(this.spouseDetailsAddressFieldsetTarget)
        }
        utils.hideTargets(this.spouseConsentsFieldsetTargets)
        break
      default:
        break
    }
  }

  // hasCoborrower: true, false
  handleHasCoborrowerInSpouseChange(hasCoborrower){
    const flag = hasCoborrower ? '1' : '0'
    inputs.set_radio_value(this.getHasCoborrowerInSpouseTarget(), flag)
    this.getHasCoborrowerInSpouseTarget().click()
  }

  handleHasCoborrowerChange(hasCoborrower){
    const flag = hasCoborrower ? '1' : '0'
    inputs.set_radio_value(this.getHasCoborrowerTarget(), flag)
    this.getHasCoborrowerTarget().click()
  }

}
