import { Controller } from "stimulus"
import * as licenceHelper from "src/js/licence_scan_polling_helper"

export default class extends Controller {
  static targets = [
    "loan",
    "withdrawSmsButton",
    "loadLicenceFromOcrButton",
    "loadPrivacyConsentButton",
    "idScanStatus"
  ]

  connect() {
    if(this.hasLoanTarget){
      let url;
      if(this.hasLoadLicenceFromOcrButtonTarget && this.hasIdScanStatusTarget && this.hasWithdrawSmsButtonTarget) {
        if(url = this.loanTarget.getAttribute("data-id_scan_process_url")) {
          setTimeout(() => {licenceHelper.pollIdScanStatus(url, this.loadLicenceFromOcrButtonTarget, this.idScanStatusTarget, this.withdrawSmsButtonTarget);}, 0);
        }
      }
    }
  }

}
