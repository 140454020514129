import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "outstandingLoansUploadContainer"
  ]

  connect() {
  }

  toggleOutstandingLoansUploadContainer(event) {
    let value = event.target.value

    if (value == "some-not-ongoing" ||
      value == "not-for-borrower" ||
      value == "mis-calculated") {

      utils.show(this.outstandingLoansUploadContainerTarget)
    } else {
      utils.hide(this.outstandingLoansUploadContainerTarget)
    }
  }

}
