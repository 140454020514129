import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"
import * as formatHelper from "src/js/format_helper"

export default class extends Controller {
  static targets = [
    "incomeBasis",
    "incomeAmount",
    "incomeBasisSection"
  ]

  connect() {
    this.calculateIncome()
  }

  incomeChanged(e) {
    this.calculateIncome()
  }

  calculateIncome() {
    if (/yearly/i.test(this.incomeBasisTarget.value)) {
      $(this.incomeBasisSectionTarget).popover('dispose')
    } else {
      this.calculate(this.incomeAmountTarget.value, this.incomeBasisTarget.value)
    }
  }

  calculate(money, basis) {
    const amount = formatHelper.moneyToAmount(money)
    if (amount && amount > 0) {
      const params = {
        income_basis: basis,
        income_amount: amount
      }
      const url = "/incomes_calculators/new.json?" + new URLSearchParams(params)
      fetch(url)
        .then(res => res.json())
        .then(data => {
          const netAnnualAmount = formatHelper.money(data['net_annual_amount'])
          this.incomeBasisSectionTarget.dataset.content = "Net annual income amount is " + netAnnualAmount
          $(this.incomeBasisSectionTarget).popover('show')
        })
        .catch(error => console.error("Error:", error))
    }

  }

}
