import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "newPassword",
    "strengthBar",
    "strengthBarParent",
    "save",
    "confirmedPassword",
    "message",
    "revealPassword"
  ]

  connect() {
  }

  estimateStrength() {
    const password = this.newPasswordTarget.value
    let code = 0
    if (password.length < 10) {
      code = -1
    } else if (password.length > 100) {
      code = -2
    } else {
      const result = zxcvbn(password)
      code = this.getCode(result.guesses_log10)
    }
    // this.confirmPassword()
    this.estimate(code)
  }

  confirmPassword() {
    const confirmedPassword = this.confirmedPasswordTarget.value
    const password = this.newPasswordTarget.value
    let message = ""
    if (password != confirmedPassword) {
      message = "Passwords don't match"
      utils.hide(this.saveTarget)
      this.messageTarget.textContent = message
      return false
    }
    else {
      utils.show(this.saveTarget)
      this.messageTarget.textContent = message
      return true
    }
  }

  estimate(code) {
    utils.show(this.strengthBarParentTarget)
    this.strengthBarTarget.classList = "progress-bar"
    this.strengthBarTarget.classList.add(this.hierarchy()[code][0], 'w-' + this.hierarchy()[code][2])
    const confirmed = this.confirmPassword()
    if (code < -1) {
      utils.hide(this.saveTarget)
    } else if (code >= 2 && confirmed) {
      utils.show(this.strengthBarParentTarget)
      this.strengthBarTarget.textContent = this.hierarchy()[code][1]
      utils.show(this.saveTarget)
    } else {
      utils.show(this.strengthBarParentTarget)
      this.strengthBarTarget.textContent = this.hierarchy()[code][1]
      utils.hide(this.saveTarget)
    }
  }

  getCode(score) {
    let code = -1;
    if (score < 0) {
      code = -1
    } else if (score < 6) {
      code = 0
    } else if (score < 12) {
      code = 1
    } else {
      code = 2
    }
    return code
  }

  hierarchy() {
    return {
      '-2': ['bg-danger', "Too long", 30],
      '-1': ['bg-danger', 'Too short', 30],
      '0': ['bg-warning', 'Need a stronger password!', 65], // (guesses < 10^6)
      '1': ['bg-info', 'Well, you are doing better ...', 80], // (guesses < 10^12)
      '2': ['bg-success', 'Ok, this will do!', 100] // (guesses >= 10^12)
    }
  }

  revealPasswordChanged() {
    if (this.revealPasswordTarget.classList.contains('mdi-eye')) {
      this.revealPasswordTarget.classList.remove('mdi-eye')
      this.revealPasswordTarget.classList.add('mdi-eye-off')
      this.newPasswordTarget.setAttribute('type', 'text')
      this.confirmedPasswordTarget.setAttribute('type', 'text')
    } else {
      this.revealPasswordTarget.classList.remove('mdi-eye-off')
      this.revealPasswordTarget.classList.add('mdi-eye')
      this.newPasswordTarget.setAttribute('type', 'password')
      this.confirmedPasswordTarget.setAttribute('type', 'password')
    }
  }

}
