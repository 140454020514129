export function showError(target, message) {
  clearError(target)
  target.parentElement.parentElement.classList.add("field_with_errors")
  const errorDiv = document.createElement("div")
  errorDiv.classList.add("error")
  errorDiv.textContent = message
  target.parentNode.insertBefore(errorDiv, target.nextSibling)
}

export function clearError(target) {
  target.parentElement.parentElement.classList.remove("field_with_errors")
  const elem = target.nextSibling
  if (elem.nodeName == 'DIV' && elem.classList.contains("error")) {
    elem.remove()
  }
}