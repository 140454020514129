import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "dropdown"
  ]
  connect() {
    const dropdown = $(this.dropdownTarget)
    const placeholder_text = dropdown.attr('placeholder')
    const options = {}
    const minimum_input_length = 3
    if (dropdown.data('ajax-url')) {
      options["ajax"] = { delay: 800, dataType: "json" }
      if (dropdown.data('minimum_input_length')) {
        minimum_input_length = dropdown.data('minimum_input_length')
      }
      options["minimumInputLength"] = minimum_input_length
    }
    if (placeholder_text) {
      options['placeholder'] = placeholder_text
      options['allowClear'] = true
    }
    dropdown.select2(options)
    if (dropdown.data('ajax-url')) {
      dropdown.on("select2:open", function () {
        let text = dropdown.find("option:selected").text()
        if (text) {
          $(this.element).find(".select2-container--open .select2-search__field").val(text)
        }
      })
    }
  }

  disconnect() {
    const select = $(this.dropdownTarget)
    if (select.hasClass("select2-hidden-accessible")) {
      select.off('select2:select')
      select.select2('destroy')
    }
  }
}