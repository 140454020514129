import { Controller } from "stimulus"

/*
 * Work around CORS issues and Turbo as suggested in
 * https://github.com/hotwired/turbo/issues/401 and endorsed by DHH.
 *
 * Feels a bit hacky but if this is the official approach then ¯\_(ツ)_/¯
 */
export default class extends Controller {
  connect() {
    if (this.urlValue()) {
      window.location.href = this.urlValue()
    } else {
      console.log("URL not set")
    }
  }

  urlValue() {
    return this.data.get("url")
  }
}

