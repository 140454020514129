// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import jQuery from "jquery"

window.jQuery = jQuery
window.$ = jQuery

require("chart.js")
import "@hotwired/turbo-rails"
require("channels")
import "bootstrap/dist/js/bootstrap"
//
// Ideally want to switch stop using select2, and manually implement the ajax lookups (vehicle, address, abn). We'll need a different solution for the multi-select dropdowns. The main reason to remove is to reduce dependency on jquery, and select2 only fires jquery events
//
import Select2 from "select2"
import "select2/dist/css/select2"
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4"

import moment from "moment"
window.moment = moment
require("tempusdominus-core")
require("tempusdominus-bootstrap-4")
import "tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4"

require.context('../images', true)
//
// Ideally want to switch to flatpickr, or something similar. Tempusdominus is a bit hard to use
//
import accounting from "src/js/accounting.min"
window.accounting = accounting

//window.Uppy = require('@uppy/core')
//window.FileInput = require('@uppy/file-input')
//window.XHRUpload = require('@uppy/xhr-upload')
//window.ProgressBar = require('@uppy/progress-bar')
//window.DragDrop = require('@uppy/drag-drop')

import "../src/stylesheets/stylesheet.scss"

import "controllers"

$.fn.select2.defaults.set("theme", "bootstrap4")
$.fn.select2.defaults.set("width", "100%")
$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
  useCurrent: false,
  icons: {
    time: 'mdi mdi-clock-outline',
    date: 'mdi mdi-calendar',
    up: 'mdi mdi-arrow-up',
    down: 'mdi mdi-arrow-down',
    previous: 'mdi mdi-chevron-left',
    next: 'mdi mdi-chevron-right',
    today: 'mdi mdi-calendar-check-outline',
    clear: 'mdi mdi-delete',
    close: 'mdi mdi-times'
  }
})

window.showAlert=function(message){
  document.querySelector("#alertModalMessage").innerHTML = message
  $("#exampleModalLong").modal({backdrop: 'static'})
  $("#exampleModalLong").modal('show')
}
