import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "toggleFormLink",
    "form",
  ]

  connect() {
  }

  toggleForm(event) {
    utils.toggle(this.formTarget);
  }

  toggleInputOptions(event) {
    this.toggleForm(event);
  }
}
