import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"

export default class extends Controller {
  static targets = [ "currentAddressSection", "previousAddressSection" ]

  handle_current_address_duration_change(timeAtAddressInMonths) {
    this.previousAddressSectionTarget.classList.toggle('d-none', (12 <= timeAtAddressInMonths))
  }
}
