import { Controller } from "stimulus"

export default class extends Controller {

  documentSelected(event) {
    const filename = event.target.value.replace(/.*[\\/]/,"")
    for (var lbl of document.querySelectorAll("label.custom-file-label[for=" + event.target.id + "]")) {
      lbl.textContent = filename
    }
  }

}
