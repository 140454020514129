import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"

export default class extends Controller {
  static targets = [
    "creditRating",
    "homeOwner",
    "hasFinanceBefore",
    "creditRatingRelatedSection",
    "payment",
    "scenario1",
    "scenario2",
    "scenario3",
  ]

  connect() {
    this.prePopulateFieldsOnLoad()
    this.showHideCreditRatingScore(inputs.get_choice_from_radio_group(this.hasFinanceBeforeTarget))
  }

  prePopulateFieldsOnLoad(){
    if(inputs.get_choice_from_radio_group(this.hasFinanceBeforeTarget) == null){
      inputs.set_radio_value(this.hasFinanceBeforeTarget, "0")
    }
    if(inputs.get_choice_from_radio_group(this.homeOwnerTarget) == null){
      inputs.set_radio_value(this.homeOwnerTarget, "0")
    }
  }

  optionFromHasFinanceButton(event) {
    this.showHideCreditRatingScore(inputs.get_choice_from_radio_click(event))
  }

  showHideCreditRatingScore(choice) {
    this.creditRatingRelatedSectionTarget.classList.toggle("d-none", (choice != 1))
  }
}
