import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {

  static targets = [
    "upload",
    "explanation",
    "referredDisclaimer",
    "confirmation"
  ]

  connect() {
    this.toggleInputOptions()
  }

  inputOptionsChanged(event) {
    this.toggleInputOptions()
  }

  toggleInputOptions() {
    if (this.hasConfirmationTarget) {
      if (this.hasReferredDisclaimerTarget) { utils.toggle(this.referredDisclaimerTarget, !this.requiresDisclaimer()) }
      if (this.hasExplanationTarget) { utils.toggle(this.explanationTarget, !this.requiresExplanation()) }
      if (this.hasUploadTarget) { utils.toggle(this.uploadTarget, !this.requiresSupportingDocument()) }
      this.toggleConfirmation()
    }
  }

  toggleConfirmation() {
    if (this.confirmationTarget.value === "") {
      this.confirmationTarget.classList.add("verification-not-provided")
    } else {
      this.confirmationTarget.classList.remove("verification-not-provided")
    }
  }

  requiresDisclaimer() {
    return ["mis-calculated", "misclassified"].includes(this.confirmationTarget.value)
  }

  requiresExplanation() {
    return ["mis-calculated", "misclassified", "some-not-ongoing", "not-for-borrower"].includes(this.confirmationTarget.value)
  }

  requiresSupportingDocument() {
    return ["upload", "mortgage-shared-with-someone-other-than-co-borrower", "some-not-ongoing", "not-for-borrower"].includes(this.confirmationTarget.value)
  }

}
