import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"
import * as formatter from 'src/js/format_helper'

export default class extends Controller {
  static targets = [
    "anticipatingFinancialChanges",
    "commentFinancialChanges",
    "confirmedSurplus",
    "bankPositionForm",
    "confirmedSurplusClasses"
  ]

  connect() {
    if (this.hasAnticipatingFinancialChangesTarget) {
      this.handleAnticipatingFinancialChanges()
    }
  }

  handleAnticipatingFinancialChanges(e) {
    let anticipatingChanges = inputs.get_choice_from_radio_group(this.anticipatingFinancialChangesTarget)
    if(e) {
      anticipatingChanges = inputs.get_choice_from_radio_click(e)
    }
    if (anticipatingChanges == 1) {
      utils.show_parent(this.commentFinancialChangesTarget)
      if (!utils.readonly()) {
        utils.enable(this.commentFinancialChangesTarget)
      }
    } else {
      utils.hide_parent(this.commentFinancialChangesTarget)
      utils.disable(this.commentFinancialChangesTarget)
    }
  }

  calculateConfirmedSurplus(e) {
    if (this.hasConfirmedSurplusTarget) {
      this.postForm(this, "/confirmed_scaled_surplus_calculators/" + this.data.get('loan-id'), this.bankPositionFormTarget)
    }
  }

  postForm(context, url, formTarget) {
    return fetch(url, {
      method: 'PATCH',
      body: new FormData(formTarget)
    })
      .then(res => res.json())
      .then(data => {
        context.confirmedSurplusTarget.textContent = formatter.money(data['confirmed_surplus'], 0)
        data['confirmed_surplus'] > 0 ? this.positiveConfirmedSurplus() : this.negativeConfirmedSurplus()
      })
      .catch(error => console.error("Error occurred when calculating confirmed surplus:", error))
  }

  negativeConfirmedSurplus() {
    this.negativeConfirmedSurplusClasses().forEach(clazz => this.confirmedSurplusClassesTarget.classList.add(clazz))
  }

  positiveConfirmedSurplus() {
    this.negativeConfirmedSurplusClasses().forEach(clazz => this.confirmedSurplusClassesTarget.classList.remove(clazz))
  }

  negativeConfirmedSurplusClasses() {
    return ["bg-warning", "text-danger", "font-weight-bold"]
  }


}
