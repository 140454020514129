import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "email",
    "confirmEmail",
    "nextBtn",
  ]

  connect() {
  }

  validateEmail() {
    const email = this.emailTarget.value
    if (inputs.validateEmail(email)) {
      utils.enable(this.nextBtnTarget)
    } else {
      utils.disable(this.nextBtnTarget)
    }
  }
}