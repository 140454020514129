import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "investmentPropertySection", "investmentIncome" ]

  connect() {
  }

  checkBoxChanged(event) {
    this.handleShowHideForCheckbox(event.target.checked)
  }
}
