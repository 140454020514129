import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "vehicleSelection",
    "refreshButton"
  ]

  connect() {
    $(this.vehicleSelectionTargets).on('select2:select', (ev) => this.vehicleSelectionChanged(ev))
  }

  vehicleSelectionChanged(ev) {
    this.refreshButtonTarget.click()
  }
}
