export function updateUrlParameter(url, params) {
  const baseUrl = window.location.origin
  const urlObj = new URL(url, baseUrl)
  const queryString = urlObj.search
  const searchParams = new URLSearchParams(queryString)
  Object.entries(params).forEach (([name, value]) => {
    if (value) searchParams.set(name, value)
  })
  urlObj.search = searchParams.toString()
  return urlObj.toString()
}
