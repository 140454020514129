import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "editNameBtn",
    "editNameFields",
  ]

  editName(e) {
    e.preventDefault()
    utils.hide(this.editNameBtnTarget)
    utils.show(this.editNameFieldsTarget)
  }

  editNameAfterAlert(e) {
    e.preventDefault()
    let confirmDialog = confirm("Please note that you'll need to re-validate the rate after changing the borrower's name")
    if (confirmDialog == true) {
      this.editName(e)
    }
  }

}
