import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"
import * as inputs from "src/js/input_helper"

export default class extends Controller {

  static targets = [
    "visa",
    "visaSection"
  ]

  connect() {
    this.render()
  }

  render() {
    this.showHideVisaAdditionalField(inputs.get_choice_from_radio_group(this.visaTarget))
  }

  citizenshipGroupChanged(ev) {
    this.showHideVisaAdditionalField(inputs.get_choice_from_radio_click(ev))
  }

  showHideVisaAdditionalField(choice) {
    utils.toggle(this.visaSectionTarget, !this.visaIsTemporary(choice))
  }

  visaIsTemporary(choice) {
    return (choice == 'other_temp')
  }

}
