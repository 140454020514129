export function get_selected_radio_from_group(radioParent) {
  return (radioParent.querySelector('input[type=radio]:checked') || radioParent.querySelector('input[type=radio][checked=checked]'))
}

export function get_choice_from_radio_group(radioParent) {
  const checked_box = get_selected_radio_from_group(radioParent)
  return (checked_box && checked_box.value)
}

export function get_choice_from_radio_click(event) {
  // This event fires before the "checked" property is set, so we have to just rely on the click event.
  var checked_box = null
  if (event.target.matches('input[type=radio]')) {
    checked_box = event.target
  } else if (event.target.matches('label')) {
    checked_box = event.target.querySelector('input[type=radio]')
  }
  if (!checked_box) {
    // If the user hasn't clicked on either the label or the radio button itself, directly, then we assume they've clicked on a parent or sibling element, so we need to return the value based on the current state
    checked_box = get_selected_radio_from_group(event.target) || get_selected_radio_from_group(event.target.parentElement) || get_selected_radio_from_group(event.target.parentElement.parentElement)
  }
  return (checked_box && checked_box.value)
}

export function set_radio_value(radioParent, targetValue) {
  radioParent.querySelectorAll('input[type=radio]').forEach(e => {
    if (e.value == targetValue) {
      e.checked = true
      e.setAttribute("checked","checked")
    } else {
      e.removeAttribute("checked")
      e.checked = false
    }
  })
  set_active_class_on_radios(radioParent)
}

export function set_active_class_on_radios(radioParent) {
  radioParent.querySelectorAll('input[type=radio]').forEach(e => {
    e.closest('label').classList.toggle('active', e.checked)
  })
}

export function validateEmail(email) {
  return /\S+@\S+\.\S+/.test(email)
}
