import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "startDate",
    "durationSection",
  ]

  connect() {
    this.calculateDuration()
  }

  startDateChanged(e) {
    this.calculateDuration()
  }

  calculateDuration() {
    if (this.hasStartDateTarget) {
      const startDate = this.startDateTarget.querySelector('input').value
      if (startDate && startDate != '') {
        this.durationSectionTarget.dataset.content = moment(startDate, 'DD/MM/YYYY').fromNow()
        $(this.durationSectionTarget).popover('show')
      } else {
        $(this.durationSectionTarget).popover('dispose')
      }
    }
  }

}
