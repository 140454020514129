import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "liabilityPaidOutSelection",
    "liabilityPaidOutDependentField",
  ]

  connect() {
    this.showHideBasedOnliabilityPaidOutSelection(inputs.get_choice_from_radio_group(this.liabilityPaidOutSelectionTarget))
  }

  liabilityPaidOutSelectionChanged(ev) {
    this.showHideBasedOnliabilityPaidOutSelection(inputs.get_choice_from_radio_click(ev))
  }

  showHideBasedOnliabilityPaidOutSelection(choice) {
    utils.toggleDependentFields(this.liabilityPaidOutDependentFieldTargets, choice)
  }

}