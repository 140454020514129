import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initialize() {
    window.clearTimeout(window.requestedResourcestimeout)
    window.requestedResourcestimeout = undefined
  }

  connect() {
    let table = document.querySelector('[data-requested-resources-statuses-target="requestedResourcesStatusesContainer"]')
    if (table && table.dataset.hasPendingRequestedResources == "true") {
      window.requestedResourcestimeout = setTimeout(e => this.initiatePolling(), 10000)
    }
  }

  initiatePolling() {
    const csrfToken = document.head.querySelector("[name='csrf-token']").content
    const statusUrl = this.data.get("url")
    document.controller = this
    let table = document.querySelector('[data-requested-resources-statuses-target="requestedResourcesStatusesContainer"]')

    if (table) {
      fetch(statusUrl, {
        method: 'GET',
        headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-CSRF-Token": csrfToken,
                  },
      }).then(response => response.json())
        .then(data => {
          document.getElementById("requested-resources-status").innerHTML = data.htmlPartial
          if (data.retry) {
            this.clearTimeout()
            window.requestedResourcestimeout = setTimeout(e => this.initiatePolling(), 10000)
          } else {
            this.clearTimeout()
            Turbo.visit(window.location)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

  }

  clearTimeout() {
    if (document.controller) {
      window.clearTimeout(window.requestedResourcestimeout)
      window.requestedResourcestimeout = undefined
    }
  }

  disconnect() {
    this.clearTimeout()
  }

}
