import { Controller } from "stimulus"
import * as inputs from "src/js/input_helper"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "typeSelector", "productDetail"
  ]

  connect() {
    this.showHideProductInput()
  }

  showHideProductInput() {
    this.showHideFields(this.typeSelectorTarget)
  }

  financingChanged(event) {
    this.showHideFields(event.target)
  }

  showHideFields(check_box) {
    utils.toggle(this.productDetailTarget, !check_box.checked)
  }
}
